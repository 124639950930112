<template>
    <v-container>
        <v-card>
            <v-card-title class="justify-space-between flex-wrap align-center" style="gap: 16px;">
                <h2 class="text-h6 text-uppercase font-weight-bold" style="word-break: break-word;">
                    <NavButton class="mr-1" style="transform: translateY(-1px)" />
                    Inteligencia de mercado
                </h2>
                <div class="d-flex flex-wrap align-items" style="gap: 16px;">
                    <v-btn 
                        color="secondary"
                        outlined
                        class="btn-generacion-reporte flex-grow-1 flex-shrink-1 flex-lg-grow-0"
                        :loading="reporteAdjudicaciones.isLoading"
                        @click.stop="generarReporteAdjudicaciones"
                    >   
                        <v-icon color="secondary" class="mr-2">mdi-seal</v-icon>
                        Reporte de adjudicaciones
                    </v-btn>
                    <v-btn 
                        color="secondary"
                        outlined 
                        class="btn-generacion-reporte flex-grow-1 flex-shrink-1 flex-lg-grow-0"
                        :loading="reporteSolicitudesObs.isLoading"
                        @click.stop="generarReporteSolicitudesObs"
                    >
                        <v-icon color="secondary" class="mr-2">mdi-file-excel</v-icon>
                        Reporte de compras OBS
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-row align="center" justify="end">
                    <v-col cols="12" md="6" lg="3">
                        <ProgresiveLoadAutocomplete 
                            v-model="filtros.id_obs"
                            label="Obs" 
                            placeholder="Seleccione un OBS"
                            itemText="nombre_completo" 
                            itemValue="id" 
                            :pageable="obs" 
                            clearable
                            hide-details
                            :loading="obs.isLoading"
                            :transform-item="mapearObs"
                            @search="searchObs"
                            @load-more="loadMoreObs"
                        />
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                        <v-text-field 
                            v-model="filtros.observacion"
                            clearable
                            outlined
                            hide-details
                            :disabled="solicitudes.isLoading"
                            label="Observación"
                            placeholder="Búsqueda por observación"
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <div class="d-flex align-center justify-center flex-wrap" style="gap: 16px;">
                            <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary" 
                            x-large
                            :disabled="solicitudes.isLoading"
                            @click.stop="limpiarFiltros"
                        >
                            Limpiar
                        </v-btn>
                        <v-btn 
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary" 
                            x-large
                            :loading="solicitudes.isLoading"
                            @click.stop="buscarSolicitudes"
                        >
                            Buscar
                        </v-btn>
                        </div>
                    </v-col>
                </v-row>
 
                <DataTableComponent 
                    :headers="headers"
                    :items="solicitudes.data"
                    :show_loading="solicitudes.isLoading"
                    :total_registros="total"
                    v-models:pagina="paginacion.page"
                    v-models:select="paginacion.per_page"
                    @paginar="manejarPaginacionSolicitudes"
                    class="mt-4"
                    dense
                    no-gutters
                >
                    <template v-slot:item.obs="{ item }">
                        {{ item.obs.nombre }}
                        <b>({{ item.obs.codigo }})</b>
                    </template>
                    <template v-slot:item.precio_unitario="{ item }">
                        {{ toMoneyFormat(item.precio_unitario) }}
                    </template>
                </DataTableComponent>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { 
    createLoadable, 
    createPageable, 
    isResponseSuccesful, 
    setLoadableResponse, 
    setPageableResponse, 
    toggleLoadable, 
    togglePageable 
} from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import ProgresiveLoadAutocomplete from '@/components/utils/ProgresiveLoadAutocomplete.vue';

const filtrosIniciales = {
    observacion: '',
    id_obs: null,
}

const paginacionInicial = {
    page: 1,
    per_page: 10,
}

const paginacionInicialObs = {
    per_page: 50, 
    page: 1,
}

export default {
    components: { NavButton, DataTableComponent, ProgresiveLoadAutocomplete },
    data: () => ({
        // Reporteria
        reporteAdjudicaciones: createLoadable(null),
        reporteSolicitudesObs: createLoadable(null),
        // Filtros
        obs: createPageable([], 50),
        obsTerm: '',
        // Solicitudes
        solicitudes: createPageable([], 10),
        paginacion: { ...paginacionInicial },
        filtros: { ...filtrosIniciales },
    }),
    computed: {
        total() {
            return this.solicitudes.pagination.total_rows;
        },
        headers() {
            return [
                { align: 'center', sortable: false, text: 'OBS', value: 'obs' },
                { align: 'center', sortable: false, text: 'Unidad de medida', value: 'unidad_medida.nombre' },
                { align: 'center', sortable: false, text: 'Observación', value: 'observacion' },
                { align: 'center', sortable: false, text: 'Precio unitario', value: 'precio_unitario' },
            ];
        },
    },
    methods: {
        // UI
        mapearObs(obs) {
            return ({ ...obs, nombre_completo: `${obs.codigo} - ${obs.nombre}` });
        },
        toMoneyFormat(value) {
            if (!value || Number.isNaN(value)) {
                return '$0.00';
            }

            return Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);
        },
        buscarSolicitudes() {
            this.paginacion = { ...paginacionInicial };
        },
        limpiarFiltros() {
            this.obsTerm = '';
            this.obs = createPageable([], 50),
            this.cargarObs();

            this.filtros = { ...filtrosIniciales };
            this.paginacion = { ...paginacionInicial };
        },
        // Filtros
        loadMoreObs() {
            if (this.obs.isLoading) return;
            const { page, per_page, total_rows } = this.obs.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;
            this.cargarObs({ page: page + 1, per_page });
        },
        async searchObs(term) {
            if (this.obsTerm === term) return;
            this.obsTerm = term;
            this.cargarObs();
        },
        async cargarObs(pagination = paginacionInicialObs) {
            togglePageable(this.obs);
            const itemsCopy = this.obs.data;
            const { data, headers } = await this.services.inteligenciaMercado.cargarCatalogoObs({
                pagination: true,
                ...pagination,
                ...this.obsTerm && ({ nombre: this.obsTerm }),
            });

            setPageableResponse(this.obs, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) return;
            this.obs.data = pagination.page > 1 ? itemsCopy.concat(data.data) : data.data;
        },
        // Data
        async generarReporteAdjudicaciones() {
            toggleLoadable(this.reporteAdjudicaciones);
            const { data } = await this.services.inteligenciaMercado.generarReporteAdjudicaciones();
            setLoadableResponse(this.reporteAdjudicaciones, data, { showAlertOnSuccess: true });
        },
        async generarReporteSolicitudesObs() {
            toggleLoadable(this.reporteSolicitudesObs);
            const { data } = await this.services.inteligenciaMercado.generarReporteSolicitudesObs({ ...this.filtros });
            setLoadableResponse(this.reporteSolicitudesObs, data, { showAlertOnSuccess: true });
        },
        async cargarSolicitudesObs() {
            togglePageable(this.solicitudes);
            const filtros = {
                pagination: true,
                ...this.paginacion,
                ...this.filtros,
            };

            const { data, headers } = await this.services.inteligenciaMercado.cargarSolicitudesObs(filtros);

            setPageableResponse(this.solicitudes, data, headers);
        },
        manejarPaginacionSolicitudes(nuevaPaginacion) {
            const { pagina, cantidad_por_pagina } = nuevaPaginacion;

            this.paginacion = { page: pagina, per_page: cantidad_por_pagina };
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarSolicitudesObs();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.cargarObs();
    },
}
</script>
<style scoped>
:deep(.btn-generacion-reporte) {
    padding: 9px 16px !important;
    max-width: 100%; 
    height: auto !important
  }
  
  :deep(.btn-generacion-reporte .v-btn__content) {
    max-width: 100%;
    word-break: break-word;
    overflow: hidden;
    white-space: normal;
  }
</style>
